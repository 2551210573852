import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import landingPage from "/styles/jss/bbhost/pages/landingPage.js";
import teamStyle from "/styles/jss/bbhost/pages/landingPageSections/teamStyle.js";
const styles = {
  ...landingPage,
  ...teamStyle,
  main: {
    background: "#f1f5f8",
    position: "relative",
    zIndex: "3",
    paddingTop: "30px",
  }
};

const useStyles = makeStyles(styles);

export default function Loading(props) {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <h3 className={classes.title}>Carregando....</h3>
        </div>
    );
}