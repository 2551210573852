import useSWR from 'swr';

import classNames from "classnames";
import dynamic from 'next/dynamic';
import Loading from 'components/Functions/Loading';
import { makeStyles } from "@material-ui/core/styles";

import HeaderSection from "pages-sections/HeaderSection.js";
const Footer = dynamic(() => import('/components/Footer/Footer.js'), { ssr: true });
// import FeaturedProductsSection from "pages-sections/HomePage-Sections/FeaturedProductsSection.js"

const FeaturedProductsSection = dynamic(() => import('pages-sections/HomePage-Sections/FeaturedProductsSection.js'), { ssr: true, loading: () => <Loading /> });
const RedeSection = dynamic(() => import('pages-sections/HomePage-Sections/RedeSection.js'), { ssr: true, loading: () => <Loading /> });
const OurDifferencesSection = dynamic(() => import('pages-sections/HomePage-Sections/OurDifferencesSection.js'), { ssr: true, loading: () => <Loading /> });

import styles from "/styles/jss/bbhost/pages/landingPage.js";
const useStyles = makeStyles(styles);

import { getBBHostApiProduct } from "constants.js";

const SEO = {
  title: "BB Host - datacenter de qualidade!",
  description: "Datacenter de qualidade!",
  url: "https://www.bbhost.com.br/",
  type: "website",
};

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function Index(props) {
  const classes = useStyles();
  
  const { data: dataConfig } = useSWR('https://api-frontend-site.bbhost.com.br/configs', fetcher)
  const { data: dataFeaturedProducts } = useSWR('https://api-frontend-site.bbhost.com.br/products-featured', fetcher)
  const { data: dataIndexMap } = useSWR('https://api-frontend-site.bbhost.com.br/maps', fetcher)
  let featuredProducts = <FeaturedProductsSection products={props.featuredProducts}/>;
  let indexMap = <RedeSection map={props.indexMap} />;
  if(dataConfig)
  {
    if(dataConfig.featured.active && dataFeaturedProducts)
      featuredProducts = <FeaturedProductsSection products={dataFeaturedProducts.products}/>;
    if(dataConfig.index_map.active && dataIndexMap)
      indexMap = <RedeSection map={dataIndexMap} />;
  }

  return (
    <div>
      <HeaderSection name="home" seo={SEO} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {featuredProducts}
          {indexMap}
          <OurDifferencesSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export async function getStaticProps({ req, res }) {
  let featuredProducts = [];
  let indexMap = {
    "name": "index",
    "style": "mapbox://styles/bbhost/ckq8j318y18f117o9bfjdsq3c?optimize=true",
    "zoom": 1,
    "center": {
      "latitude": 0,
      "longitude": 0
    },
    "locations": [
      {
        "latitude": -23.5983, 
        "longitude": -46.8487,
        "text": "Datacenter Cotia",
        "description": "Datacenter, POP, MSR, IX/PTT",
        "type": {
          "datacenter": true,
          "pop": true,
          "msr": true
        }
    }
    ]
  };

  try
  {
    let products_data_api = await getBBHostApiProduct("featured");
    products_data_api = products_data_api.data;
    if(products_data_api.products !== undefined)
    {
      featuredProducts = products_data_api.products;
    }
    
    return {
      props: {
        featuredProducts,
        indexMap
      },
    }
  }
  catch (error) 
  {
    console.error(error);
    return {
      props: {
        featuredProducts,
        indexMap
      },
    }
  }
}